var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"h":"100%","py":"8","px":{ base: '6', lg: 0 }}},[_c('c-text',{attrs:{"fontSize":"2xl","fontWeight":"700","as":"h1"}},[_vm._v("Create Presentation")]),_c('c-text',[_vm._v("Pick a template and create your presentation")]),_c('c-flex',{attrs:{"flexWrap":"wrap","my":"5"}},[_c('c-pseudo-box',{attrs:{"w":"250px","h":"150px","boxShadow":"0px 1px 6px rgba(0, 0, 0, 0.16)","bg":"#fff","borderRadius":"8px","mr":"10","mb":"10","display":"flex","flexDirection":"column","alignItems":"center","justifyContent":"center","cursor":"pointer"},on:{"click":function($event){return _vm.openNewPresentationModal('pitch_deck')}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          width: '25px',
          height: '25px',
          mb: 3,
        }),expression:"{\n          width: '25px',\n          height: '25px',\n          mb: 3,\n        }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v("New Pitch deck")])],1),_c('c-pseudo-box',{attrs:{"w":"250px","h":"150px","boxShadow":"0px 1px 6px rgba(0, 0, 0, 0.16)","bg":"#fff","borderRadius":"8px","mr":"10","mb":"10","display":"flex","flexDirection":"column","alignItems":"center","justifyContent":"center","cursor":"pointer"},on:{"click":function($event){return _vm.openNewPresentationModal('market_plan')}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          width: '25px',
          height: '25px',
          mb: 3,
        }),expression:"{\n          width: '25px',\n          height: '25px',\n          mb: 3,\n        }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v("New Market Plan")])],1),_c('c-pseudo-box',{attrs:{"w":"250px","h":"150px","boxShadow":"0px 1px 6px rgba(0, 0, 0, 0.16)","bg":"#fff","borderRadius":"8px","mr":"10","mb":"10","display":"flex","flexDirection":"column","alignItems":"center","justifyContent":"center","cursor":"pointer"},on:{"click":function($event){return _vm.openNewPresentationModal('foundation')}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          width: '25px',
          height: '25px',
          mb: 3,
        }),expression:"{\n          width: '25px',\n          height: '25px',\n          mb: 3,\n        }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})]),_c('c-text',{attrs:{"textAlign":"center","maxWidth":"80%","fontWeight":"600"}},[_vm._v("New Foundation Presentation")])],1)],1),_c('c-box',{attrs:{"my":"5"}},[_c('c-text',{attrs:{"fontSize":"xl","fontWeight":"700","as":"h2"}},[_vm._v("Recent Presentations")]),_c('c-box',{attrs:{"my":"5","w":"100%"}},[(_vm.isFetchingPresentations)?_c('c-spinner',{attrs:{"thickness":"4px","speed":"0.65s","empty-color":"green.200","color":"vue.500","size":"xl"}}):_c('c-flex',{attrs:{"flexWrap":"wrap"}},_vm._l((_vm.presentations),function(presentation){return _c('c-pseudo-box',{key:presentation.id,attrs:{"mr":"10","mb":"10","display":"flex","flexDirection":"column","role":"group","cursor":"pointer"},on:{"click":function($event){return _vm.openPresentation(presentation)}}},[_c('c-pseudo-box',{attrs:{"mb":"3","w":"250px","h":"150px","borderRadius":"8px","boxShadow":"sm","overflow":"hidden","transition":"box-shadow 0.3s","_groupHover":{ boxShadow: 'xl' }}},[_c('img',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"src":presentation.theme.image,"alt":"","srcset":"","w":"100%","h":"100%","objectFit":"cover","objectPosition":"center"}})]),_c('c-pseudo-box',{attrs:{"_groupHover":{ color: 'vc-orange.400' },"fontWeight":"600","fontSize":"sm","as":"p","transition":"color 0.3s"}},[_vm._v(_vm._s(presentation.title))])],1)}),1)],1)],1),_c('NewPresentation',{attrs:{"type":_vm.presentationType,"isModalOpen":_vm.isNewPresentationModalOpen},on:{"close":_vm.closeNewPresentationModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }