<template>
  <router-view></router-view>
</template>

<script>
import { getFontsList } from '@/services/presentation';
import { mapMutations } from 'vuex';

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const fontRes = await getFontsList();
      next((vm) => {
        vm.setFonts(fontRes.data.items);
      });
    } catch (e) {
      next();
    }
  },
  methods: {
    ...mapMutations({
      setFonts: 'presentation/setFonts',
    }),
  },
};
</script>
