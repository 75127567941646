<template>
  <c-box>
    <c-modal :is-open="isModalOpen" :on-close="close" :size="'xl'">
      <c-modal-content ref="content" minWidth="70rem">
        <c-modal-header>
          <!-- <c-editable
        :value="$v.noteForm.title.$model"
        font-size="2xl"
        mb="2"
        fontWeight="600"
        color="gray.500"
        @change="onTitleChange"
        :submitOnBlur="false"
      >
        <c-editable-preview minWidth="120px" />
        <c-editable-input />
      </c-editable> -->
          <c-editable
            :default-value="presentationTitle"
            w="max-content"
            font-size="lg"
            placeholder="Type name here..."
            fontWeight="600"
            @change="onPresentationTitleChange"
            :submitOnBlur="false"
          >
            <c-editable-preview minWidth="120px" />
            <c-editable-input />
          </c-editable>
        </c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-box minHeight="30rem">
            <c-text as="h3" mb="5" fontWeight="600" fontSize="lg">
              Choose Template
            </c-text>
            <c-spinner
              thickness="4px"
              speed="0.65s"
              empty-color="green.200"
              color="vue.500"
              size="xl"
              v-if="isFetchingTemplates"
            />
            <c-flex v-else flexWrap="wrap">
              <c-pseudo-box
                v-for="template in templates"
                :key="template.id"
                mr="10"
                mb="10"
                display="flex"
                flexDirection="column"
                role="group"
                cursor="pointer"
                @click="selectTemplate(template)"
              >
                <c-pseudo-box
                  mb="3"
                  w="250px"
                  h="150px"
                  borderRadius="8px"
                  boxShadow="sm"
                  overflow="hidden"
                  transition="box-shadow 0.3s"
                  :_groupHover="{ boxShadow: 'xl' }"
                >
                  <img
                    v-chakra
                    :src="template.image"
                    alt=""
                    srcset=""
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </c-pseudo-box>
                <c-pseudo-box
                  :_groupHover="{ color: 'vc-orange.400' }"
                  fontWeight="600"
                  fontSize="sm"
                  as="p"
                  transition="color 0.3s"
                  >{{ template.name }}</c-pseudo-box
                >
              </c-pseudo-box>
            </c-flex>
          </c-box>
        </c-modal-body>
        <!-- <c-modal-footer>
          <c-button variant-color="blue" mr="3"> Save </c-button>
          <c-button @click="close">Cancel</c-button>
        </c-modal-footer> -->
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
    <c-alert-dialog :is-open="isCreatingPresentation">
      <c-alert-dialog-overlay />
      <c-alert-dialog-content mt="20">
        <c-alert-dialog-body>
          <c-flex
            textAlign="center"
            py="8"
            align="center"
            direction="column"
            justify="center"
          >
            <c-spinner mb="3" color="blue.400" thickness="2px" size="xl" />
            <c-text fontWeight="500"> Creating Presentation </c-text>
          </c-flex>
        </c-alert-dialog-body>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import { createPresentation, getTemplates } from '@/services/presentation';

export default {
  props: {
    type: {
      type: String,
    },
    isModalOpen: {
      tyoe: Boolean,
    },
  },
  data() {
    return {
      templates: [],
      isTemplatePreviewMode: false,
      selectedTemplate: null,
      isCreatingPresentation: false,
      isFetchingTemplates: false,
      presentationTitle: 'Untitled Presentation',
    };
  },
  computed: {
    ...mapState({
      activeCompany: (state) => state.company.activeCompany,
    }),
  },
  watch: {
    isModalOpen() {
      if (this.isModalOpen) {
        this.getTemplates();
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async selectTemplate(template) {
      // this.isTemplatePreviewMode = true;
      this.selectedTemplate = template;
      this.isCreatingPresentation = true;
      const data = {
        title: this.presentationTitle,
        type: this.type,
        themeId: template.id,
        companyId: this.activeCompany.id,
        data: {
          elevator: {},
          solution: {},
          how_it_works: {},
          problem: {},
          value_proposition: {},
          target_customers: {},
          business_model: {},
          market_size: {},
          compete: {},
          point_of_differentiation: {},
          go_to_market_strategy: {},
          market_goals: {},
          traction: {},
          team: {},
          advisory_board: {},
          capital_raised: {},
          financials: {},
          goals: {},
        },
      };
      try {
        const res = await createPresentation(data);
        console.log('new pay', res);
        this.$router.push(
          `/app/presentation/edit/${res.data.insert_presentation_one.id}`
        );
      } catch (e) {
        console.log({ e });
        this.isCreatingPresentation = false;
        this.$toast({
          title: 'Error!!!',
          description: `An error occurred, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async getTemplates() {
      this.isFetchingTemplates = true;
      try {
        const { data } = await getTemplates(this.type);
        this.isFetchingTemplates = false;
        this.templates = data.theme;
      } catch (e) {
        console.log({ e });
        this.isFetchingTemplates = false;
      }
    },
    onPresentationTitleChange(e) {
      if (typeof e === 'string') {
        this.presentationTitle = e;
      }
    },
  },
};
</script>

<style></style>
