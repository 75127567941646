<template>
  <c-box h="100%" py="8" :px="{ base: '6', lg: 0 }">
    <c-text fontSize="2xl" fontWeight="700" as="h1">Create Presentation</c-text>
    <c-text>Pick a template and create your presentation</c-text>
    <c-flex flexWrap="wrap" my="5">
      <c-pseudo-box
        w="250px"
        h="150px"
        boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
        bg="#fff"
        borderRadius="8px"
        mr="10"
        mb="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        @click="openNewPresentationModal('pitch_deck')"
      >
        <svg
          fill="#ef5323"
          v-chakra="{
            width: '25px',
            height: '25px',
            mb: 3,
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle" />
        </svg>
        <c-text fontWeight="600">New Pitch deck</c-text>
      </c-pseudo-box>
      <!--         v-if="isStaging" -->
      <c-pseudo-box
        w="250px"
        h="150px"
        boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
        bg="#fff"
        borderRadius="8px"
        mr="10"
        mb="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        @click="openNewPresentationModal('market_plan')"
      >
        <svg
          fill="#ef5323"
          v-chakra="{
            width: '25px',
            height: '25px',
            mb: 3,
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle" />
        </svg>
        <c-text fontWeight="600">New Market Plan</c-text>
      </c-pseudo-box>
      <c-pseudo-box
        w="250px"
        h="150px"
        boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
        bg="#fff"
        borderRadius="8px"
        mr="10"
        mb="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        @click="openNewPresentationModal('foundation')"
      >
        <svg
          fill="#ef5323"
          v-chakra="{
            width: '25px',
            height: '25px',
            mb: 3,
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle" />
        </svg>
        <c-text textAlign="center" maxWidth="80%" fontWeight="600"
          >New Foundation Presentation</c-text
        >
      </c-pseudo-box>
    </c-flex>
    <c-box my="5">
      <c-text fontSize="xl" fontWeight="700" as="h2"
        >Recent Presentations</c-text
      >
      <c-box my="5" w="100%">
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="vue.500"
          size="xl"
          v-if="isFetchingPresentations"
        />
        <c-flex v-else flexWrap="wrap">
          <c-pseudo-box
            v-for="presentation in presentations"
            :key="presentation.id"
            mr="10"
            mb="10"
            display="flex"
            flexDirection="column"
            role="group"
            cursor="pointer"
            @click="openPresentation(presentation)"
          >
            <c-pseudo-box
              mb="3"
              w="250px"
              h="150px"
              borderRadius="8px"
              boxShadow="sm"
              overflow="hidden"
              transition="box-shadow 0.3s"
              :_groupHover="{ boxShadow: 'xl' }"
            >
              <img
                v-chakra
                :src="presentation.theme.image"
                alt
                srcset
                w="100%"
                h="100%"
                objectFit="cover"
                objectPosition="center"
              />
            </c-pseudo-box>
            <c-pseudo-box
              :_groupHover="{ color: 'vc-orange.400' }"
              fontWeight="600"
              fontSize="sm"
              as="p"
              transition="color 0.3s"
              >{{ presentation.title }}</c-pseudo-box
            >
          </c-pseudo-box>
        </c-flex>
      </c-box>
    </c-box>
    <NewPresentation
      :type="presentationType"
      :isModalOpen="isNewPresentationModalOpen"
      @close="closeNewPresentationModal"
    />
  </c-box>
</template>

<script>
import { getPresentations } from '@/services/presentation';
import NewPresentation from './components/NewPresentation.vue';
import { mapState } from 'vuex';
export default {
  components: { NewPresentation },
  data() {
    return {
      presentationType: null,
      isNewPresentationModalOpen: false,
      isFetchingPresentations: false,
      presentations: [],
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
  },
  mounted() {
    this.getPresentations();
  },
  methods: {
    openNewPresentationModal(type) {
      this.presentationType = type;
      this.isNewPresentationModalOpen = true;
    },
    closeNewPresentationModal() {
      this.presentationType = null;
      this.isNewPresentationModalOpen = false;
    },
    getPresentations() {
      this.isFetchingPresentations = true;
      getPresentations(this.activeCompany.id)
        .then((res) => {
          this.isFetchingPresentations = false;
          this.presentations = res.data.presentation;
        })
        .catch(() => {
          this.isFetchingPresentations = false;
        });
    },
    openPresentation(presentation) {
      // window.open(
      //   `${window.location.origin}/app/presentation/edit/${presentation.id}`,
      //   '_parent'
      // );
      this.$router.push(`/app/presentation/edit/${presentation.id}`);
    },
  },
};
</script>

<style></style>
